<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content>
      <b-avatar
        size="40"
        :variant="vehicleCode ? 'light-success' : 'light-danger'"
        badge
        class="badge-minimal"
        :badge-variant="vehicleCode ? 'success' : 'danger'"
      >
        <feather-icon
          icon="TruckIcon"
          size="22"
        />
      </b-avatar>
    </template>

    <b-dropdown-item
      v-if="vehicleData"
      link-class="d-flex align-items-center flex-wrap"
    >
      <feather-icon
        size="16"
        icon="TruckIcon"
        class="mr-50"
      />
      <span class="text-wrap mr-50">{{ vehicleData.ds_automezzo }}</span>
      <span class="targa">{{ vehicleData.targa }}</span>
    </b-dropdown-item>

    <b-dropdown-divider v-if="vehicleData" />

    <b-dropdown-item
      :to="{ name: 'set-vehicle' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        :icon="vehicleCode ? 'RefreshCcwIcon' : 'PlusIcon'"
        class="mr-50"
      />
      <span>{{ vehicleCode ? 'Cambia veicolo' : 'Imposta veicolo' }}</span>
    </b-dropdown-item>

    <b-dropdown-item
      v-if="vehicleCode"
      link-class="d-flex align-items-center"
      @click="removeVehicle"
    >
      <feather-icon
        size="16"
        icon="XIcon"
        class="mr-50"
      />
      <span>Scollega veicolo</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import { logout } from '@mixins/auth'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import store from '@/store'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
  },
  mixins: [logout],
  data() {
    return {
      avatarText,
    }
  },
  computed: {
    vehicleCode() {
      return this.$store.state.vehicle.vehicle.code
    },
    vehicleData() {
      return this.$store.state.vehicle.vehicle.data
    },
  },
  methods: {
    removeVehicle() {
      store.commit('vehicle/REMOVE_VEHICLE')
      this.$router.replace(getHomeRouteForLoggedInUser())
    },
  },
}
</script>
