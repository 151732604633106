<template>
  <layout-vertical
    v-if="$root.$data.user_logged"
    :nav-menu-items="navMenuItems"
    :class="[$route.name, appScope]"
  >

    <template #vertical-menu-header="slotProps">
      <ul class="nav navbar-nav flex-row">
        <!-- Logo & Text -->
        <li class="nav-item mr-auto">
          <b-link
            class="navbar-brand"
            to="/"
          >
            <span class="brand-logo" :class="appScope">
              <b-img :src="appScope === 'arredo3' ? appLogoImage : appLogoImagePrima" alt="logo" />
            </span>
            <h2 class="brand-text">
              {{ appName }}
            </h2>
          </b-link>
        </li>

        <!-- Toggler Button -->
        <li class="nav-item nav-toggle">
          <b-link class="nav-link modern-nav-toggle">
            <feather-icon
              icon="XIcon"
              size="20"
              class="d-block d-xl-none"
              @click="slotProps.toggleVerticalMenuActive"
            />
            <feather-icon
              :icon="slotProps.collapseTogglerIcon === 'unpinned' ? 'CircleIcon' : 'DiscIcon'"
              size="20"
              class="d-none d-xl-block collapse-toggle-icon"
              @click="slotProps.toggleCollapsed"
            />
          </b-link>
        </li>
      </ul>
    </template>

    <template #breadcrumb>
      <!--
      <div class="row mt-1">
        <div class="col-12">
          <div role="alert" aria-live="polite" aria-atomic="true" class="alert alert-danger" data-v-aa799a9e="">
            <h4 class="alert-heading">ATTENZIONE! ATTIVITÀ DI MANUTENZIONE STRAORDINARIA</h4>
            <div class="alert-body">
              <span>Dalle ore 18.00 alle ore 19.00 di lunedì 12 settembre il portale potrebbe non essere raggiungibile a causa di un'attività di manutenzione straordinaria.</span>
            </div>
          </div>
        </div>
      </div>
      -->
      <app-breadcrumb />
    </template>

    <router-view />

    <template #navbar="slotProps">
      <app-navbar-vertical-layout :toggle-vertical-menu-active="slotProps.toggleVerticalMenuActive" />
    </template>

    <app-customizer
      v-if="showCustomizer"
      slot="customizer"
    />

    <template #footer>
      <app-footer />
    </template>

  </layout-vertical>
</template>

<script>
import { BLink, BImg } from 'bootstrap-vue'
import AppNavbarVerticalLayout from '@core_custom/layouts/components/app-navbar/AppNavbarVerticalLayout'
import AppBreadcrumb from '@core_custom/layouts/components/AppBreadcrumb'
import AppFooter from '@core_custom/layouts/components/AppFooter'
import LayoutVertical from '@core/layouts/layout-vertical/LayoutVertical.vue'
import AppCustomizer from '@core/layouts/components/app-customizer/AppCustomizer.vue'
import { $themeConfig } from '@themeConfig'
import navMenuItems from '@/navigation/vertical'
import store from '@/store'

export default {
  components: {
    AppCustomizer,
    LayoutVertical,
    AppNavbarVerticalLayout,
    AppBreadcrumb,
    AppFooter,
    BLink,
    BImg,
  },
  data() {
    return {
      showCustomizer: $themeConfig.layout.customizer,
      appName: $themeConfig.app.appName,
      appScope: store.state.scope.appScope,
      appLogoImage: $themeConfig.app.appLogoImage,
      appLogoImagePrima: $themeConfig.app.appLogoImagePrima,
      navMenuItems,
    }
  },
}
</script>
