<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      COPYRIGHT © {{ new Date().getFullYear() }}
      <b-link
        class="ml-25"
        :href="website"
        target="_blank"
      >{{ company }}</b-link>
      <span class="d-none d-sm-inline-block">, All rights Reserved</span>
    </span>

    <span class="float-md-right d-none d-md-block text-italic">{{ appFullName }}
      <feather-icon
        icon="TruckIcon"
        size="21"
        class="text-primary stroke-current"
      />
    </span>
  </p>
</template>

<script>
import { BLink } from 'bootstrap-vue'
import { $themeConfig } from '@themeConfig'
import store from '@/store/index'
import appConfig from '../../../../config.json'

export default {
  components: {
    BLink,
  },
  data() {
    return {
      appFullName: $themeConfig.app.appFullName,
      company: appConfig[store.state.scope.appScope].company,
      website: appConfig[store.state.scope.appScope].company_website,
    }
  },
}
</script>
