// eslint-disable-next-line import/no-cycle
import axiosIns from '../../libs/axios'
import store from '@/store'

const logout = {
  methods: {
    logout() {
      axiosIns.post('/logout').then(response => {
        // Remove userData from localStorage
        localStorage.removeItem('userData')
        localStorage.removeItem('userToken')
        store.commit('vehicle/REMOVE_VEHICLE')

        if (this.$router.name !== 'auth-login') {
          // Redirect to login page
          this.$router.push({ name: 'auth-login' })
        }
      })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.log(error)
        })
      // Reset ability
      // this.$ability.update(initialAbility)
    },
  },
}

export {
  // eslint-disable-next-line import/prefer-default-export
  logout,
}
